.stages:global(.ant-tabs):global(.ant-tabs-card) .ant-tabs-tab {
  color: black;
  font-weight: normal;
}

.stages:global(.ant-tabs):global(.ant-tabs-card) :global .ant-tabs-tab.ant-tabs-tab-active {
  font-weight: bolder;
  color: black;
}

.stages:global(.ant-tabs):global(.ant-tabs-card) :global .ant-tabs-tab.ant-tabs-tab-disabled::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #e8e8e8ba;
}

.stages :global .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: bolder;
  color: black;
}

.stages :global .ant-tabs-tab:hover {
  color: black;
}

:global .ant-tabs-tab-btn.ant-tabs-tab-btn:focus, :global .ant-tabs-tab-remove.ant-tabs-tab-remove:focus, :global .ant-tabs-tab-btn.ant-tabs-tab-btn:active, :global .ant-tabs-tab-remove.ant-tabs-tab-remove:active {
  color: black;
}

.stages:global(.ant-tabs):global(.ant-tabs-card) :global .ant-tabs-tab:nth-child(1) {
  background: #D2222D;
}

.stages:global(.ant-tabs):global(.ant-tabs-card) :global .ant-tabs-tab:nth-child(2) {
  background: #FFBF00;
}

.stages:global(.ant-tabs):global(.ant-tabs-card) :global .ant-tabs-tab:nth-child(3) {
  background: #238823;
}

.stages :global .ant-tabs-nav.ant-tabs-nav {
  margin-bottom: 0;
}

.stages :global .ant-tabs-tab.ant-tabs-tab.ant-tabs-tab.ant-tabs-tab {
  border-bottom: 0;
}