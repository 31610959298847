body {
  margin: 0;
  font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  font-weight: bold;
}

.ant-btn, .ant-table {
  font-family: Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-weight: bold;
}

.ant-table-thead  > tr > th, .ant-table-thead > tr > th.ant-table-column-sort {
  background-color: #a5a5a5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-table-tbody > tr > td {
  border: none;
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td, .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background-color: rgba(0, 0, 0, 0.3);
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: inherit;
}

.paired_table tr:nth-child(2n) td{
  border-bottom: solid black 1px;
}

.Main {
  max-width: 80%;
  margin: auto;
  text-align: center;
}

.sensor_table_row:nth-child(1) {
  background: deeppink;
}
