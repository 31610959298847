.manualCalibrationValues {
  display: flex;
  margin-bottom: 10px;
}

.manualCalibrationBox {
  background: #F2F2F2;
  padding: 10px;
  width: 612px;
  box-sizing: content-box;
}

:global(.ant-card-head.ant-card-head) {
  border: none;
}

.warning {
  color: rgb(94, 94, 94);
  font-size: 20px;
  font-family: "Open Sans";
  font-weight: bold;
  line-height: normal;
  margin: 0px;
  margin-bottom: 20px;
  text-align: center;
  padding: 0px 1rem;
  width: 100%;
}

:global(.ant-card-head-title.ant-card-head-title) {
  font-size: 16px;
  font-family: "Open Sans";
  font-weight: 300;
  color: rgb(94 94 94);
  padding-bottom: 0;
}

.lastValueTitle {
  box-sizing: border-box;
  color: rgb(94, 94, 94);
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  text-align: center;
}

.lastValueValue {
  box-sizing: border-box;
  color: rgb(55, 214, 122);
  font-family: Open Sans;
  font-size: 59.5px;
  font-weight: 700;
  line-height: normal;
}

.lastValueTime {
  box-sizing: border-box;
  color: rgb(94, 94, 94);
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  text-align: center;
}

.defaultTitle {
  box-sizing: border-box;
  color: rgb(94, 94, 94);
  display: block;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  text-align: left;
}

.lastValueValueWrapper {
  align-items: baseline;
  box-sizing: border-box;
  color: rgb(94, 94, 94);
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: center;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
}

.manualCalibrationValues :global(.ant-card-body) {
  padding-top: 60px;
}

.sendButton.sendButton.sendButton {
  appearance: button;
  background-attachment: scroll;
  background-clip: border-box;
  background-color: rgb(204, 204, 204);
  background-image: none;
  background-origin: padding-box;
  background-position: 0% 0%;
  background-position-x: 0%;
  background-position-y: 0%;
  background-repeat: repeat;
  background-size: auto;
  border-bottom-color: rgb(238, 238, 238);
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-outset: 0;
  border-image-repeat: stretch;
  border-image-slice: 100%;
  border-image-source: none;
  border-image-width: 1;
  border-left-color: rgb(238, 238, 238);
  border-left-style: solid;
  border-left-width: 1px;
  border-right-color: rgb(238, 238, 238);
  border-right-style: solid;
  border-right-width: 1px;
  border-top-color: rgb(238, 238, 238);
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top-style: solid;
  border-top-width: 1px;
  box-sizing: border-box;
  color: rgb(238, 238, 238);
  cursor: pointer;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  margin-bottom: 0px;
  margin-left: 262.467px;
  margin-right: 262.483px;
  margin-top: 0px;
  overflow: visible;
  overflow-x: visible;
  overflow-y: visible;
  padding-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  text-align: center;
  text-transform: none;
  transition-delay: 0s, 0s;
  transition-duration: 0.2s, 0.25s;
  transition-property: background, color;
  transition-timing-function: ease-in-out, ease-in-out;
}

.valuesTable th {
  box-sizing: border-box;
  color: rgb(94, 94, 94);
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
}


.valuesTable td, .valuesTable th {
  border-bottom: 1px solid rgb(229 229 229);
  height: 28px;

}

.valuesTable {
  width: 100%;
  box-sizing: border-box;
  color: rgb(94, 94, 94);
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
}

.valuesTableWrapper.valuesTableWrapper {
  margin-left: 10px;
  margin-right: 19px;
  overflow: auto;
}

.manualCalibrationBox :global(.ant-input-number) {
  width: 100%;
}