.wrapper {
  max-width: 500px;
  margin: 10vh auto 0 auto;
  min-height: 100px;
}

.error {
  color: red;
  text-align: center;
}

.googleBtn {
  width: 184px;
  height: 42px;
  background-color: #4285f4;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  margin: 200px auto;
}

.googleBtn .googleIconWrapper {
  position: absolute;
  margin-top: 1px;
  margin-left: 1px;
  width: 40px;
  height: 40px;
  border-radius: 2px;
  background-color: #fff;
}
.googleBtn .googleIcon {
  position: absolute;
  margin-top: 11px;
  margin-left: 11px;
  width: 18px;
  height: 18px;
}
.googleBtn .btnText {
  float: right;
  margin: 11px 11px 0 0;
  color: #fff;
  font-size: 14px;
  letter-spacing: 0.2px;
  font-family: "Roboto";
}

.googleBtn:hover {
  box-shadow: 0 0 6px #4285f4;
}
.googleBtn:active {
  background: #1669F2
}