.panel {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  background: #FFBF00;
}

.nextButton svg {
  width: 42px;
  height: 40px;
}

.invalid {
  background: #d2222ec4;
}

.valid {
  background: #238823c4;
}

:global(.ant-table-tbody) > tr.valid.valid:hover td {
  background: #238823c3;
}

.disabled {
  background: gainsboro;
}

:global(.ant-table-tbody) > tr.valid.valid td {
  background: none;
}

.dry {
  background: #FFBF00c4;
}

.statistic {
  font-size: 1.3rem;
}

.actions {
  display: flex;
  align-items: center;
}