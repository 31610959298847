.panel {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  background: #238823;
}

.nextButton svg {
  width: 42px;
  height: 40px;
}

.stage3_fulltable :global .ant-table-row:nth-child(4n+2), .stage3_fulltable :global .ant-table-row:nth-child(4n+3) {
  background: #98e2ffe3;
}

.stage3_fulltable :global .ant-table-row:nth-child(4n+4), .stage3_fulltable :global .ant-table-row:nth-child(4n+5) {
  background: #5bd0ffe3;
}

.stage3_fulltable .noPair:global(.ant-table-row) {
  background: rgba(173, 173, 173, 0.609);
}

.hideColumn {
  display: none;
  float: right;
}

td.hideColumn {
  visibility: hidden;
  display: block;
}

.downloadButton {
  background: white;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  text-align: center;
  font-size: 24px;
}

.statistic {
  font-size: 1.3rem;
}

.actions {
  display: flex;
  align-items: center;
}

.unlockButton {
  display: flex;
  align-items: center;
  padding: 0 15px;
}

.rightMenu {
  display: flex;
  justify-content: center;
  align-items: center;
}